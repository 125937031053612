import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Article5 from "../components/Article5"

const IndexPage = () => (
  <Layout headerColor={"black"}>
    <SEO title="Quelles différences entre nos 5 ramens ?" />
    <Article5 />
  </Layout>
)

export default IndexPage
