import React from "react"
import styled from "@emotion/styled"
import articles from "../articles-db"

const Blog = () => {
  const article = articles.find(art => art.id === "5")

  return (
    <ArticleSection>
      <ArticleContainer>
        <ArticleTextContainer>
          <ArticleTitle>{article.title}</ArticleTitle>
          <ArticleDate>Publié le {article.date}</ArticleDate>

          <ArticleImageContainer>
            <img
              src={article.image}
              alt={article.title}
              className="articleImage"
            ></img>
          </ArticleImageContainer>
          <ArticleIntro>{article.intro}</ArticleIntro>
          <ArticleSubTible>1 - Le ramen Shoyu </ArticleSubTible>
          <ArticleText>
            C’est la recette du ramen classique qu’on retrouve systématiquement
            à Tokyo et qui est idéale pour s’initier à ces saveurs. Shoyu
            signifie en japonais soja et c’est cet élément qui donne au bouillon
            son arôme. Il fait parti des soupes de ramen de type chintan,
            c’est-à-dire une soupe claire, mijotée à basse température.
            <br></br>
            <br></br>
            Chez Hara-Kiri, notre chef l’agrémente de pousses de bambou, de
            mizuna (un chou japonais légèrement poivré), de cébette, d’algue
            nori et de naruto, cette décoration culinaire japonaise faite à
            partir de pâté de poisson, cuit à la vapeur.
          </ArticleText>
          <ArticleSubTible>2 - Le ramen Shoyu Yuzu</ArticleSubTible>
          <ArticleText>
            Le ramen Shoyu Yuzu est une déclinaison du ramen Shoyu avec un zeste
            de yuzu, ce citron japonais au goût marqué d’agrume, qui se situe
            entre le citron vert, le pamplemousse jaune et la mandarine. Cette
            touche acidulée vient réveiller les papilles et s’accorde alors
            parfaitement à tous les autres ingrédients précédemment cités.
          </ArticleText>
          <ArticleSubTible>3 - Le ramen Miso </ArticleSubTible>
          <ArticleText>
            Le ramen Miso est originaire d’Hokkaido et fait partie lui aussi des
            soupes de type chintan. Le miso est une pâte japonaise au goût très
            prononcé et salé. Il est fabriqué à partir de graines de soja, du
            riz, du sel marin et du koji, un mélange de blé ou de riz avec un
            champignon. Il est ensuite fermenté plusieurs fois et offre au
            bouillon un arôme gourmand.
          </ArticleText>
          <ArticleSubTible>4 - Le ramen Spicy</ArticleSubTible>
          <ArticleText>
            Le ramen Spicy est conçu à partir de la base de notre ramen miso
            auquel nous ajoutons trois types de piments différents, qui viennent
            donner au bouillon sa couleur rouge vif. Agrémenté de filaments de
            piment, de poireaux et de pousses de bambou, ce ramen est idéal pour
            ceux qui aiment mettre le feu à leurs papilles !
          </ArticleText>
          <ArticleSubTible>5 - Le ramen à la truffe noire</ArticleSubTible>
          <ArticleText>
            Afin d’apporter notre touche française, nous avons demandé au chef
            d’exprimer sa créativité et de revisiter les traditionnelles
            recettes japonaises pour proposer un ramen signature : le ramen à la
            truffe noire. Ce champignon qui pousse majoritairement en France
            vient donner au bouillon cette rondeur et cette gourmandise qu’on
            recherche en mangeant un ramen. Les saveurs puissantes de la truffe
            noire se marient alors parfaitement avec les autres champignons
            japonais qui agrémentent ce plat comme les Enoki et les Shiitaké.
          </ArticleText>
        </ArticleTextContainer>
      </ArticleContainer>
    </ArticleSection>
  )
}

const ArticleSection = styled.section`
  margin-top: 100px;
  padding: 0 10%;
`

const ArticleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`

const ArticleTextContainer = styled.div`
  width: 80%;
  /* padding: 20px 60px; */
  margin: auto;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 20px;
  }
`

const ArticleTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
`
const ArticleDate = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
`
const ArticleIntro = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`

const ArticleSubTible = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`

const ArticleText = styled.p`
  font-size: 14px;
  margin-bottom: 40px;
`

const ArticleImageContainer = styled.div`
  width: 100%;
  height: 400px;
  background-color: #fff;
  margin-bottom: 40px;

  .articleImage {
    width: 100%;
    height: 50%;
    object-fit: cover;
    height: 400px;
  }

  @media screen and (max-width: 640px) {
    width: 80vw;
    height: 80vw;
  }
`

export default Blog
